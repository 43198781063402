
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    text-align:center;
    max-width:900px;
    margin:0 auto;
}

header {
    padding:2rem;
    display:flex;
    flex-direction: column;
    gap:1rem;
}
.header__logo__cont{
    border-bottom:solid 1px #eee;
}
.header__logo{
    max-width: 120px;
}
main {
    flex: 1;
    padding:2rem;
}

.contact-approval{
    text-align:start;
}

.steps{
    display:flex;
    flex-direction: column;
    gap:1rem;
    max-width:400px;
    margin: 0 auto;
}

.steps .ant-card-meta-description{
    color:black;
}